html {
  font-size: 10px;
}

@font-face {
  font-family: sneaker-script-narrow;
  src: url(fonts/sneakersscriptnarrow.ttf);
}

@font-face {
  font-family: URWFormCond-Italic;
  src: url(fonts/URWFormCond-Italic.ttf);
}

body{
  padding:0;
  margin:0;
  width:100%;
  height:100%;
  background:radial-gradient(#444c4c, #363d3d);
}

* {
  margin: 0;
  padding: 0;
}

#root {
  width: 100vw;
  height: 100%;
  padding: 0px;
  overflow: hidden;
}

.grecaptcha-badge { 
  visibility: hidden; 
}

.snackbar-default {
  margin-bottom: 50px;
  border: solid 2px black;
  border-radius: 10px;
  box-shadow: 10px 5px 5px black;
}

.snackbar-default div {
  background-color: #FAEBD7;
  color: black;
  flex-flow: row;
  font-family: 'URWFormCond-Italic';
  font-size: medium;
}

.snackbar-default button{
  height: 45px;
  width: 100px;
  border-radius: 5px;
  border: 2px solid #000;
  color: black;
  cursor: pointer;
  background-color: transparent;
  transition: 0.5s;
  text-transform: uppercase;
  font-family: "sneaker-script-narrow";
  font-size: 10px;
  letter-spacing: 2px;
}